/* Placed.css */
.team-section {
    padding: 60px 0;
  }
  
  .team-card {
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    padding: 0; /* Make sure there's no padding */
  }
  
  .team-card:hover {
    transform: translateY(-10px);
  }
  
  .team-img {
    margin: 0; /* Ensure no margin */
    padding: 0; /* Ensure no padding */
  }
  
  .team-img img {
    width: 100%;
  height: 10%;

  display: block;
  }
  
  .team-content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    padding: 0px;
  }
  
  .multi-social a {
    margin: 0 5px;
    color: #fff;
  }
  
  .icon-btn.expand-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    color: #fff;
  }
  
  .team-info {
    margin-top: 0px;
    text-align: center;
    padding-bottom: 10px;
  }
  
  .team-title a {
    color: #000;
    text-decoration: none;
    font-size: 18px;
    font-weight: bold;
  }
  
  .team-desig {
    color: #777;
  }
  
  .slick-arrow {
    background: #333;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }
  
  .slick-prev {
    left: -40px;
  }
  
  .slick-next {
    right: -40px;
  }
  
  .slick-prev:before,
  .slick-next:before {
    content: '';
  }
  
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  li {
    @apply p-4
  }

  button {
    @apply text-white border bg-indigo-600 border-indigo-600
    hover:bg-transparent hover:text-indigo-600 rounded-md
  }
}

body {
  font-family: 'Montserrat', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif;
}

/* Add more styles for other elements as needed */
/* Style for the popup overlay */
.popup-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }
  
  /* Style for the popup content */
  .popup-content {
    background: #fff;
    padding: 10px;
    border-radius: 8px;
    margin-top: 75px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 300px; /* Adjust the width as needed */
  }
  
  /* Style for form elements */
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: -2px;
    font-weight: bold;
  }
  
  input {
    width: 100%;
    padding: 1px;
    box-sizing: border-box;
    margin-bottom: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  button {
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-right: 10px;
  }
  
  /* Style for primary button */
  .btn-primary {
    background-color: #3498db;
    color: #fff;
  }
  
  /* Style for secondary button */
  .btn-secondary {
    background-color: #95a5a6;
    color: #fff;
  }
  
  /* Responsive design for small screens */
  @media (max-width: 768px) {
    .popup-content {
      width: 90%;
    }
  }
  